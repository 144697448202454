import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import { Link } from 'gatsby';

const notFound = () => {
  return (
    <Layout seo={{}}>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <div className='h-full flex flex-col items-center'>
        <span className='text-5xl font-semibold'>Qui non c'è nulla</span>
        <img
          className='rounded'
          src='https://ecommerce-scraper.sprintstudio.co/wp-content/uploads/2020/11/404-1.gif'
        ></img>
        <span className='text-3xl font-semibold'>ed è rischioso rimanerci</span>
      </div>
    </Layout>
  );
};

export default notFound;
